import imgCongratulation from './images/gojo_wallet.svg'
import styled from 'styled-components'
import logo from './images/logo.png'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
const Congratulation = ({ location }) => {
  const { width, height } = useWindowSize()
  return (
    <Wraper>
      <a href="https://voucher.lab.qup.vn/"> check redirect</a>
    </Wraper>
  )
}

const Wraper = styled.div`
  padding: 0 10px;
`

export default Congratulation