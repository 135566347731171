import React, {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import FormikControl from './components/Formik/FormikControl'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { Formik, Form } from 'formik'
import ClipLoader from "react-spinners/ClockLoader"
import * as Yup from 'yup'
import AllCountries from './AllCountries'
import logo from './images/logo.png'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

const URL_CC_API = process.env.REACT_APP_CC_URL

function App() {
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const countryCode = 'my'
  const phoneRegExp = /^[\+\d]?(?:[\d-.\s()]*)$/;
  const validationSchema = Yup.object({
    voucher: Yup.string().required("This field is required.").min(4, "This code is invalid."),
    phone: Yup.string().min(8, "This field is required.").matches(phoneRegExp, "Invalid phone number.").required("This field is required."),
  });

  const onSubmit = (values, {
      setFieldError
    }) => {
    let params = JSON.parse(JSON.stringify(values));
    setLoading(true)
    fetch(`${URL_CC_API}/vouchercode/apply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: params.phone && params.phone.replace(/[\s-]/g, ''),
        redeemCode: params.voucher,
        fleetId: 'gojosg',
        userId: ''
      }),
    })
    .then(response => response.json())
    .then(data => {
      let { returnCode, response } = data && data.res &&  data.res.res
      if (returnCode === 200) {
        history.push({
          pathname: "/congratulation",
          state: {amount: response.amount, currencySymbol: response.currencySymbol}
        });
      } else {
        switch (returnCode) {
          case 536:
            setFieldError('voucher', 'This code is invalid or expired.');
            break;
          case 537:
            setFieldError('phone', 'Invalid phone number.');
            break;
          case 538:
            setFieldError('voucher', 'This field is required.');
            break;
          default:
            setFieldError('voucher', 'Server error.');
            break;
        };
      }
      setLoading(false)
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoading(false)
    });
    return null
  }

  const getCountryCode = () => {
    let data = '';
    const countries = AllCountries.getCountries();
    data = countries.filter(country => {
      return country.iso2 === countryCode.toLowerCase()
    })
    return data;
  };

  const phoneInit = getCountryCode()[0] ? `+${getCountryCode()[0].dialCode}` : '';

  const validate = values => {
      let errors = {};
      if (values.phone) {
          const phoneNumber = parsePhoneNumberFromString(values.phone, countryCode);
          if (values.phone[0] !== '+') { 
              errors.phone = "Invalid phone number.";
          };
          if (!phoneNumber) {
              errors.phone = "Invalid phone number.";
          };
          if (phoneNumber && !phoneNumber.isValid()) {
              errors.phone = "Invalid phone number.";
          };
      }
      return errors;
  }

  return (
    <div className="App">
      <img src={logo} alt="logo" className="logoHeader"/>
      <Formik
        initialValues={{
          phone: phoneInit,
          voucher: ""
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validate={validate}
      >
        {
          formik => {
            return (
              <Form className="form-action">
                <div className="formInput">
                  <FormikControl
                    control='inputPhoneIntlTel'
                    type='text'
                    label='Your phone number'
                    name='phone'
                    placeholder={phoneInit}
                    touched={formik.touched}
                    errors={formik.errors}
                    formik={formik}
                    countryCode={countryCode}
                  />
                  <FormikControl
                    control='input'
                    type='text'
                    label="Enter voucher code"
                    name='voucher'
                    placeholder="Enter voucher code"
                    values={formik.values}
                    touched={formik.touched}
                    errors={formik.errors}
                  />
                </div>
                <div className="btn_container">
                  <button
                    type='submit'
                    disabled={!formik.isValid}
                    className={(!formik.isValid) ?
                        "apply-btn button"
                        : "apply-btn button valid"
                    }
                  >
                      Redeem
                  </button>
                </div>
              </Form>
            )
          }
        }
      </Formik>
      {
        isLoading &&
        <div id="loading">
          <ClipLoader
            size={60}
            color="white"
          />
        </div>
      }
    </div>
  );
}

export default App;
