import imgCongratulation from './images/gojo_wallet.svg'
import styled from 'styled-components'
import logo from './images/logo.png'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
const Congratulation = ({ location }) => {
  const { width, height } = useWindowSize()

  const handleBackHomeClick = () => {
    const navi = navigator.userAgent.toLowerCase();
    const isAndroid = navi.indexOf('android') > -1; // android check
    const isIphone = navi.indexOf('iphone') > -1;
    const data = {
      action: 'closeVoucherScreen',
      data: '',
    };
    const returnURL = location && location.state && location.state.returnURL || ''
    if (isAndroid) {
      // Android
      try {
        window.JSBridge.showMessageInNative(JSON.stringify(data));
      } catch (error) {
        console.log('Error when postMessage to App');
      }
    }
    if (isIphone) {
      // iOS
      try {
        window.webkit.messageHandlers.JSBridge.postMessage(data);
      } catch (error) {
        console.log('Error when postMessage to Ios App');
      }
    }
    if(returnURL) {
      window.location.replace(returnURL);
    }
  }
  return (
    <Wraper>
      <img src={location.state && location.state.logo} alt="logo" className="logoHeader"/>
      <div className="imgValue">
        <img src={imgCongratulation} />
        {/* <p>
          <span className="currentcy">{location.state && location.state.currencySymbol}</span>
          <span className="value">{location.state && location.state.amount}</span>
        </p> */}
      </div>
      <h3>Congratulation!</h3>
      <p>
        {/* You're successfully redeemed. <br/>  */}
        The reward of <b>{`${location.state && location.state.currencySymbol} ${location.state && location.state.amount}`}</b> has been credited into your wallet.
      </p>
      {
        width < 768 &&
        <button onClick={handleBackHomeClick}>
          Back Home
        </button>
      }
      <Confetti
        width={width}
        height={height}
      />
    </Wraper>
  )
}

const Wraper = styled.div`
  padding: 0 10px;
  text-align: center;
  display: flex;
  color: #363a45;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  button {
    background-color: #f0070e;
    color: white;
    border: none;
    width: 100%;
    border-radius: 10px;
    padding: 8px 0;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  h3 {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 1.2rem
  }
  p {
    font-size: 1.1rem;
    padding: 0 30px;
  }
  .imgValue {
    position: relative;
    p {
      position: absolute;
      color: #e1061d;
      display: flex;
      left: 50%;
      top: 22%;
      transform: translate(-50%, -50%);
      .currentcy {
        font-weight: bold;
      }
      .value {
        font-size: 31px;
        font-weight: bold;
      }
    }
  }
`

export default Congratulation