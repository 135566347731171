import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import TextError from "./TextError";
import { TextField } from 'formik-material-ui';

function InputField(props) {
    const { label, name, touched , errors, ...rest } = props;

    return (
        <Form.Group>
            <Field id={name} name={name} {...rest} className={touched[name] && errors[name] ? "error": null} component={TextField} label={label}/>
            <ErrorMessage name={name} component={TextError} />
        </Form.Group>
    )
}

export default InputField