import React from 'react';
import { ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import TextError from "./TextError";

function InputPhoneField(props) {
    const { label, name, touched, errors, formik, countryCode, ...rest } = props;

    const handleInputPhone = (status, value, countryData, number, isBlur) => {
        if (isBlur) {
            formik.setFieldTouched(name);
        } else {
            if (status) {
                formik.setFieldValue(name, number);
            } else {
                formik.setFieldValue(name, value);
            }
        }
    };


    return (
        <div className="MuiFormControl-root MuiTextField-root">
            <label className={"MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"} data-shrink="false" htmlFor="standard-basic" id="standard-basic-label">
                {label}
            </label>
            < div className = "MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl" >
                <IntlTelInput
                    fieldId={name}
                    fieldName={name}
                    // containerClassName="intl-tel-input"
                    inputClassName={`${touched[name] && errors[name] ? "error" : null}`}
                    css={['intl-tel-input', 'form-control']}
                    // utilsScript={'libphonenumber.js'}
                    nationalMode={false}
                    // autoHideDialCode={false}
                    defaultCountry={countryCode.toLowerCase()}
                    preferredCountries={[countryCode.toLowerCase()]}
                    {...rest}
                    value={formik.values.phone}
                    onPhoneNumberChange={(status, value, countryData, number, id) => {
                        handleInputPhone(status, value, countryData, number, false);
                    }}
                    onPhoneNumberBlur={(status, value, countryData, number, id) => {
                        handleInputPhone(status, value, countryData, number, true);
                    }}
                    format
                    formatOnInit={false}
                />
            </div>
            {/* <div>
                              <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                                <IntlTelInputApp
                                    css={["intl-tel-input", "form-control"]}
                                    utilsScript={"libphonenumber.js"}
                                    value={this.state[item.key]}
                                    onPhoneNumberChange={(status, value, countryData, number, id) => {
                                      this.handlePhoneChange(status, value, countryData, number, id, item.key);
                                    }}
                                    key={this.props.baseInfo.fleet.countryCode || Math.random()}
                                    disabled={item.key == "phone"}
                                />
                              </div>
                            </div>
                          </div> */}
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default InputPhoneField