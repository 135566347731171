import React from 'react';
import InputField from "./InputField";
import InputPhoneIntlTelField from './InputPhoneIntlTelField';

function FormikControl(props) {
    const { control, ...rest } = props;
    switch (control) {
        case 'input':
            return <InputField {...rest} />
        case 'inputPhoneIntlTel':
            return <InputPhoneIntlTelField {...rest} />
        default: return null
    }
}

export default FormikControl